import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { Button } from 'primeng/button';

import { WelcomePopupService } from '../../service';
import { ThickenerWelcomePopupComponent } from '../welcome-popup/thickener-welcome-popup.component';

@Component({
  standalone: true,
  imports: [CommonModule, Button],
  templateUrl: './thickener-explorer-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThickenerExplorerHeaderComponent implements OnInit {
  readonly #vcr = inject(ViewContainerRef);
  readonly #welcomePopupService = inject(WelcomePopupService);

  ngOnInit(): void {
    if (this.#welcomePopupService.notShowPopup) return;

    const compRef = this.#vcr.createComponent(ThickenerWelcomePopupComponent);
    compRef.changeDetectorRef.detectChanges();
  }

  showThickenerPopup() {
    const compRef = this.#vcr.createComponent(ThickenerWelcomePopupComponent);
    compRef.changeDetectorRef.detectChanges();
  }
}
