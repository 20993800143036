<ev-modal-dialog
  [$title]="title"
  [$subtitle]="subtitle"
  [$showBrandLogo]="true"
  [$maxWidthClass]="'w-3/5'"
  [$showFooter]="false"
  (closeDialog)="onDialogClose()"
>
  <div class="flex flex-col sm:flex-row">
    <section
      id="key-benefits-section"
      class="flex flex-col justify-between w-full sm:w-2/3"
    >
      <div class="benefits p-2">
        <p class="text-lg m-0">
          <b>Your benefits</b> of using Thickener Explorer:
        </p>

        <ul class="m-4">
          <li>
            Instantly predict viscometric properties and optimize your
            formulation
          </li>
          <li>Decrease pre-formulation trial & error</li>
          <li>
            Access a broad ingredient and formulation database of BASF products
          </li>
        </ul>
      </div>

      <div class="flex items-center gap-2 mt-16">
        <p-checkbox
          inputId="notShowPopup"
          [(ngModel)]="notShowPopup"
          [binary]="true"
          size="large"
        ></p-checkbox>
        <label for="notShowPopup" class="text-sm"> Do not show me again </label>
      </div>
    </section>

    <section
      id="instructions-video-section"
      class="right-side w-full sm:w-1/3 p-4 pr-8"
    >
      <p-card class="w-full">
        <img
          alt="Card"
          class="w-full rounded-t-sm"
          src="assets/images/video-thumbnail.jpg"
        />

        <div class="p-4 pb-0">
          <p class="m-0 font-medium">Video</p>
          <p class="m-0 text-lg mb-2">
            Formulate faster with Thickener Explorer
          </p>
          <div class="mt-2 pb-2">
            <a class="block font-bold" routerLink="/products">
              Watch Instructions Video Now!
            </a>
          </div>
        </div>
      </p-card>
    </section>
  </div>
</ev-modal-dialog>
