import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ModalDialogComponent } from '@ev-portals/ev/frontend/ui-library';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';

import { WelcomePopupService } from '../../service/welcome-popup.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ModalDialogComponent,
    CheckboxModule,
    CardModule,
    ButtonModule,
  ],
  templateUrl: './thickener-welcome-popup.component.html',
  styleUrl: './thickener-welcome-popup.component.scss',
})
export class ThickenerWelcomePopupComponent implements OnInit {
  #welcomePopupService = inject(WelcomePopupService);

  protected notShowPopup = false;

  protected title = 'Thickener Explorer - Your Digital Solution for Lubricant Formulation';
  protected subtitle =
    'Discover the perfect thickening component for your lubricant formulation with BASF’s Thickener Explorer. Choose your desired application and base stock, then explore key technical properties such as viscosity, shear stability, and thickening efficiency across our portfolio of thickener components. Set your viscometric targets and get the best product recommendations to meet your performance needs.';

  ngOnInit() {
    this.notShowPopup = this.#welcomePopupService.notShowPopup;
  }

  onDialogClose() {
    this.#welcomePopupService.setNotShowInLocalStorage(this.notShowPopup);
  }
}
