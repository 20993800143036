import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { PermissionsEnum } from '@ev-portals/cp/frontend/shared/api-client';
import { AuthenticationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { SalesMigrationService } from '@ev-portals/cp/frontend/shared/util';
import { LayoutService } from '@ev-portals/ev/frontend/util';
import { take } from 'rxjs';

@Component({
  selector: 'cp-user-profile-nav',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './profile-nav.component.html',
  styleUrls: ['./profile-nav.component.scss'],
})
export class ProfileNavComponent {
  #layoutService = inject(LayoutService);
  #authenticationService = inject(AuthenticationService);
  #salesMigrationService = inject(SalesMigrationService);

  // Migration State
  readonly salesLogicPermitted = this.#salesMigrationService.isSalesLogicPermitted();

  isSmallerThanMedium$ = this.#layoutService.isSmallerThanLarge$;
  hasCheckoutPermission$ = this.#authenticationService.hasPermission(PermissionsEnum.Checkout);
  hasFinancialOverviewPermission$ = this.#authenticationService.hasPermission(
    PermissionsEnum.FinancialOverview,
  );
  hasOrderHistoryPermission$ = this.#authenticationService.hasPermission(
    PermissionsEnum.OrderHistory,
  );

  $user = toSignal(this.#authenticationService.loggedInUser$, { requireSync: true });

  onLogoutAttempt(): void {
    this.#authenticationService
      .logout()
      .pipe(take(1))
      .subscribe(this.#authenticationService.logoutHandler);
  }
}
