<div class="flex flex-col text-white">
  <span class="text-5xl font-semibold"
    >Thickener Explorer - Your Digital Solution for Lubricant Formulation
  </span>
  <p-button
    class="mt-4"
    (onClick)="showThickenerPopup()"
    label="See Instructions!"
  ></p-button>
</div>
