{
  "name": "customer-portal-frontend",
  "$schema": "../../node_modules/nx/schemas/project-schema.json",
  "projectType": "application",
  "sourceRoot": "apps/customer-portal-frontend/src",
  "prefix": "ev",
  "i18n": {
    "sourceLocale": {
      "code": "en-GB",
      "baseHref": "/en-GB/"
    },
    "locales": {
      "en-US": {
        "translation": "apps/customer-portal-frontend/src/i18n/terms.en-US.xlf",
        "baseHref": "/en-US/"
      },
      "es-MX": {
        "translation": "apps/customer-portal-frontend/src/i18n/terms.es-MX.xlf",
        "baseHref": "/es-MX/"
      },
      "pt-BR": {
        "translation": "apps/customer-portal-frontend/src/i18n/terms.pt-BR.xlf",
        "baseHref": "/pt-BR/"
      },
      "fr-CA": {
        "translation": "apps/customer-portal-frontend/src/i18n/terms.fr-CA.xlf",
        "baseHref": "/fr-CA/"
      },
      "zh-CN": {
        "translation": "apps/customer-portal-frontend/src/i18n/terms.zh-CN.xlf",
        "baseHref": "/zh-CN/"
      },
      "zh-TW": {
        "translation": "apps/customer-portal-frontend/src/i18n/terms.zh-TW.xlf",
        "baseHref": "/zh-TW/"
      },
      "ko-KR": {
        "translation": "apps/customer-portal-frontend/src/i18n/terms.ko-KR.xlf",
        "baseHref": "/ko-KR/"
      },
      "ja-JP": {
        "translation": "apps/customer-portal-frontend/src/i18n/terms.ja-JP.xlf",
        "baseHref": "/ja-JP/"
      },
      "vi-VN": {
        "translation": "apps/customer-portal-frontend/src/i18n/terms.vi-VN.xlf",
        "baseHref": "/vi-VN/"
      }
    }
  },
  "targets": {
    "build": {
      "executor": "@angular-devkit/build-angular:application",
      "outputs": [
        "{options.outputPath}"
      ],
      "options": {
        "outputPath": "dist/apps/customer-portal-frontend",
        "index": "apps/customer-portal-frontend/src/index.html",
        "browser": "apps/customer-portal-frontend/src/main.ts",
        "polyfills": [
          "apps/customer-portal-frontend/src/polyfills.ts"
        ],
        "tsConfig": "apps/customer-portal-frontend/tsconfig.app.json",
        "inlineStyleLanguage": "scss",
        "assets": [
          "apps/customer-portal-frontend/src/favicon.ico",
          "apps/customer-portal-frontend/src/assets",
          "apps/customer-portal-frontend/src/git-version.json",
          {
            "glob": "**/*",
            "input": "./node_modules/@atoms/angular-components/src/assets",
            "output": "./assets"
          }
        ],
        "styles": [
          "apps/customer-portal-frontend/src/scss/styles.scss",
          "node_modules/@atoms/angular-components/atoms-globals/atoms-core.css",
          "node_modules/primeng/resources/primeng.css",
          "node_modules/@atoms/angular-components/src/assets/styles/application/atoms-app.css",
          "apps/customer-portal-frontend/src/scss/dark-blue.css"
        ],
        "scripts": [
          "friendly-challenge/widget"
        ],
        "i18nMissingTranslation": "error",
        "allowedCommonJsDependencies": [
          "lodash",
          "highcharts",
          "highcharts/modules/export-data",
          "highcharts/modules/exporting"
        ]
      },
      "configurations": {
        "prod": {
          "budgets": [
            {
              "type": "initial",
              "maximumWarning": "500kb",
              "maximumError": "3000kb"
            },
            {
              "type": "anyComponentStyle",
              "maximumWarning": "2kb",
              "maximumError": "4kb"
            }
          ],
          "fileReplacements": [
            {
              "replace": "apps/customer-portal-frontend/src/environments/environment.ts",
              "with": "apps/customer-portal-frontend/src/environments/environment.prod.ts"
            }
          ],
          "outputHashing": "all",
          "localize": true
        },
        "preprod": {
          "budgets": [
            {
              "type": "initial",
              "maximumWarning": "500kb",
              "maximumError": "3000kb"
            },
            {
              "type": "anyComponentStyle",
              "maximumWarning": "2kb",
              "maximumError": "4kb"
            }
          ],
          "fileReplacements": [
            {
              "replace": "apps/customer-portal-frontend/src/environments/environment.ts",
              "with": "apps/customer-portal-frontend/src/environments/environment.preprod.ts"
            }
          ],
          "outputHashing": "all",
          "localize": true
        },
        "qa": {
          "budgets": [
            {
              "type": "initial",
              "maximumWarning": "500kb",
              "maximumError": "3000kb"
            },
            {
              "type": "anyComponentStyle",
              "maximumWarning": "2kb",
              "maximumError": "4kb"
            }
          ],
          "fileReplacements": [
            {
              "replace": "apps/customer-portal-frontend/src/environments/environment.ts",
              "with": "apps/customer-portal-frontend/src/environments/environment.qa.ts"
            }
          ],
          "outputHashing": "all",
          "localize": true
        },
        "dev": {
          "budgets": [
            {
              "type": "initial",
              "maximumWarning": "5000kb",
              "maximumError": "10000kb"
            },
            {
              "type": "anyComponentStyle",
              "maximumWarning": "2kb",
              "maximumError": "5kb"
            }
          ],
          "optimization": false,
          "extractLicenses": false,
          "sourceMap": true,
          "namedChunks": true,
          "fileReplacements": [
            {
              "replace": "apps/customer-portal-frontend/src/environments/environment.ts",
              "with": "apps/customer-portal-frontend/src/environments/environment.dev.ts"
            }
          ],
          "outputHashing": "all",
          "localize": true
        },
        "local-mock": {
          "optimization": false,
          "extractLicenses": false,
          "sourceMap": true,
          "namedChunks": true,
          "fileReplacements": [
            {
              "replace": "apps/customer-portal-frontend/src/environments/environment.ts",
              "with": "apps/customer-portal-frontend/src/environments/environment.local-mock.ts"
            }
          ]
        },
        "local": {
          "optimization": false,
          "extractLicenses": false,
          "sourceMap": true,
          "namedChunks": true
        }
      },
      "defaultConfiguration": "prod"
    },
    "serve": {
      "executor": "@angular-devkit/build-angular:dev-server",
      "configurations": {
        "prod": {
          "buildTarget": "customer-portal-frontend:build:prod"
        },
        "preprod": {
          "buildTarget": "customer-portal-frontend:build:preprod"
        },
        "local": {
          "buildTarget": "customer-portal-frontend:build:local"
        },
        "local-mock": {
          "buildTarget": "customer-portal-frontend:build:local-mock"
        }
      },
      "defaultConfiguration": "local",
      "options": {
        "proxyConfig": "apps/customer-portal-frontend/proxy.conf.json",
        "buildTarget": "customer-portal-frontend:build"
      }
    },
    "extract-i18n": {
      "executor": "@angular-devkit/build-angular:extract-i18n",
      "options": {
        "outputPath": "apps/customer-portal-frontend/src/i18n",
        "outFile": "terms.xlf",
        "buildTarget": "customer-portal-frontend:build"
      }
    },
    "lint": {
      "executor": "@nx/eslint:lint",
      "options": {
        "lintFilePatterns": [
          "apps/customer-portal-frontend/src/**/*.ts",
          "apps/customer-portal-frontend/src/**/*.html"
        ]
      }
    },
    "test": {
      "executor": "@nx/jest:jest",
      "outputs": [
        "{workspaceRoot}/coverage/apps/customer-portal-frontend"
      ],
      "options": {
        "jestConfig": "apps/customer-portal-frontend/jest.config.ts"
      }
    }
  },
  "tags": [
    "scope:customer-portal-frontend",
    "type:app"
  ]
}
