import { Routes } from '@angular/router';
import { profileRoutes } from '@ev-portals/cp/frontend/dashboard/shell';
import { eventRoutes } from '@ev-portals/cp/frontend/event/shell';
import { ThickenerExplorerHeaderComponent } from '@ev-portals/cp/frontend/explore/feat-thickener-explorer-header';
import { exploreRoutes } from '@ev-portals/cp/frontend/explore/shell';
import {
  LandingPageComponent,
  LandingPageHeaderComponent,
} from '@ev-portals/cp/frontend/info/feature-landing-page';
import { infoRoutes } from '@ev-portals/cp/frontend/info/shell';
import { productRoutes } from '@ev-portals/cp/frontend/product/shell';
import { cartRoutes } from '@ev-portals/cp/frontend/purchase/shell';
import { requestRoutes } from '@ev-portals/cp/frontend/request/shell';
import {
  autoLoginGuard,
  CheckoutPermissionGuard,
  LocationGuard,
  SalesLogicPermissionGuard,
  UserGuard,
  UserRegisterGuard,
} from '@ev-portals/cp/frontend/shared/auth/util';
import { BaseLayoutComponent, PageNotFoundComponent } from '@ev-portals/cp/frontend/shared/feature';
import { FeatureAccountComponent } from '@ev-portals/cp/frontend/user/feature-account';
import { LocationSelectionComponent } from '@ev-portals/cp/frontend/user/feature-user-profile';

export const appRoutes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        component: LandingPageComponent,
        data: {
          headerComponent: LandingPageHeaderComponent,
        },
        canActivate: [UserRegisterGuard, autoLoginGuard()],
      },
      {
        path: 'login',
        loadComponent: () =>
          import('@ev-portals/cp/frontend/shared/auth/feature-login').then(m => m.LoginComponent),
      },
      {
        path: 'events',
        children: eventRoutes,
        canActivate: [UserRegisterGuard, UserGuard],
      },
      {
        path: 'info',
        children: infoRoutes,
      },
      {
        path: 'cart',
        children: cartRoutes,
        canActivate: [SalesLogicPermissionGuard],
        canActivateChild: [UserGuard, LocationGuard, CheckoutPermissionGuard],
      },
      {
        path: 'products',
        children: productRoutes,
        canActivateChild: [UserRegisterGuard, LocationGuard],
        data: {
          minifiedBackground: true,
          whiteDesign: true,
        },
      },
      {
        path: 'requests',
        children: requestRoutes,
        canActivateChild: [UserRegisterGuard],
      },
      {
        path: 'account-register',
        component: FeatureAccountComponent,
      },
      {
        path: 'select-location',
        component: LocationSelectionComponent,
        canActivate: [UserGuard],
      },
      {
        path: 'user',
        children: profileRoutes,
        canActivateChild: [UserGuard, LocationGuard],
      },
      {
        path: 'explore',
        data: { headerComponent: ThickenerExplorerHeaderComponent },
        children: exploreRoutes,
      },
      {
        path: 'error',
        loadChildren: () => import('./error.routes').then(m => m.errorRoutes),
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
];
