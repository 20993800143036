import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WelcomePopupService {
  #localStorageName = 'thickenerWelcomeNotShow';

  public setNotShowInLocalStorage(notShow: boolean): void {
    const value = String(notShow);

    localStorage.setItem(this.#localStorageName, value);
  }

  public get notShowPopup() {
    return localStorage.getItem(this.#localStorageName) === 'true';
  }
}
