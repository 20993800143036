import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params } from '@angular/router';
import { AccountRegisterDialogComponent } from '@ev-portals/cp/frontend/shared/feature';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';

@Component({
  standalone: true,
  imports: [CommonModule, AccountRegisterDialogComponent],
  templateUrl: './feature-account.component.html',
  styleUrl: './feature-account.component.scss',
})
export class FeatureAccountComponent {
  #activatedRoute = inject(ActivatedRoute);

  title = $localize`Create your BASF account now`;
  subtitle = $localize`Join us to get exclusive access. Download more documents, process your request faster and track the progress!`;

  $origin = signal<string | undefined>(undefined);

  #navigationService = inject(NavigationService);

  constructor() {
    this.#activatedRoute.queryParams.pipe(takeUntilDestroyed()).subscribe(params => {
      this.#getOrigin(params);
    });
  }

  #getOrigin(params?: Params) {
    const origin = params?.['origin'];

    if (!origin) {
      this.#getOriginFromLocalStorage();
    } else {
      this.$origin.set(origin);
    }
  }

  #getOriginFromLocalStorage(): void {
    this.$origin.set(localStorage.getItem('origin') || undefined);
  }

  onClose(): void {
    localStorage.setItem('initial-register-popup-closed', 'true');
    this.#navigationService.navigateToOriginalDestination();
  }
}
