{
  "dirty": true,
  "raw": "v1.79.1-20-gbadca11a6-dirty",
  "hash": "gbadca11a6",
  "distance": 20,
  "tag": "v1.79.1",
  "semver": {
    "options": {
      "loose": false,
      "includePrerelease": false
    },
    "loose": false,
    "raw": "v1.79.1",
    "major": 1,
    "minor": 79,
    "patch": 1,
    "prerelease": [],
    "build": [],
    "version": "1.79.1"
  },
  "suffix": "20-gbadca11a6-dirty",
  "semverString": "1.79.1+20.gbadca11a6.dirty"
}